export enum Role {
    FREE = 'free',
    MANAGER = 'manager',
    ADMIN = 'admin',
    PLAYER = 'player',
    PREMIUM = 'premium',
    INFLUENCER = 'influencer',
    TRIAL = 'trial',
    PARTNER = 'partner',
    WRITER = 'writer',
    WRITER_INTERN = 'writer-intern',
    WRITER_EDITOR = 'writer-editor'
}

export const Roles = Object.values(Role) as Role[];

export interface Account {
    id: string;
    _id: string;
    username: string;
    fullname: string;
    slug: string;
    email: string;
    affiliateId: string;
    shortId: string;
    lastVisited: Date;
    joinedOn: Date;
    roles: Role[];
    accounts: {
        fb: {
            id: string;
            token: string;
            displayName: string;
            email: string;
            photo: string;
        };
        google: {
            id: string;
            token: string;
            displayName: string;
            email: string;
            photo: string;
        };
        steam: {
            id: string;
        };
        bnet: {
            id: string;
            token: string;
            battleTag: string;
        };
        discord: {
            id: string;
            username: string;
            discriminator: string;
            avatar: string;
            email: string;
        };
        youtube: [
            {
                id: string;
                email: string;
                displayName: string;
                accessToken: string;
                refreshToken: string;
                photo: string;
            }
        ];
    };
    currentProfileImageId: string;
    profileImagePath: string | null;
    profileImageIds: object;
    consentGrants: any;
    drawingsEnabled: boolean;
    earningsEnabled: boolean;
    pendingUsernameChange: boolean;
    dob: Date;
    bio: string;
    profileColor: string;
}

